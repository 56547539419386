import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Title from "../components/Headings/Title"

const ReactLive = ({ data }) => {
  const { frontmatter, body } = data.allMdx.edges[0].node

  return (
    <Layout>
      <Seo title="React Live" />
      <section className="react-live-section">
        <Title>{frontmatter.title}...</Title>
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            <MDXRenderer>{body}</MDXRenderer>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ReactLive

export const query = graphql`
  query ReactLive {
    allMdx(filter: { fileAbsolutePath: { regex: "//react-live.mdx/" } }) {
      edges {
        node {
          body
          frontmatter {
            title
          }
        }
      }
    }
  }
`
